import { AccessDeniedComponent, LoginComponent, SharedLogoutComponent } from '@agroone-front/shared'
import { UserPermissions } from '@agroone/entities'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { UserGuard } from './core/guards/user.guard'

const routes: Routes = [
  { path: 'access-denied', component: AccessDeniedComponent },
  {
    path: 'logout',
    component: SharedLogoutComponent,
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'planification',
        loadChildren: () => import('./features/planification/planification.module').then((m) => m.PlanificationModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_PLANIFICATION],
        },
      },
      {
        path: 'admin',
        loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_ADMIN],
        },
      },
      {
        path: 'suivi',
        loadChildren: () => import('./features/stocks/stocks.module').then((m) => m.StocksModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_STOCKS],
        },
      },
      {
        path: 'pc-radio',
        loadChildren: () => import('./features/pc-radio/pc-radio.module').then((m) => m.PcRadioModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_PCRADIO],
        },
      },
      {
        path: 'crop',
        loadChildren: () => import('./features/crop/crop.module').then((m) => m.CropModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_OFFER],
        },
      },
      {
        path: 'trucks',
        loadChildren: () => import('./features/trucks/trucks.module').then((m) => m.TrucksModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_TRUCKS],
        },
      },
      {
        path: 'factories',
        loadChildren: () => import('./features/factories/factories.module').then((m) => m.FactoriesModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_FACTORIES],
        },
      },

      /**
       * V2 ROUTES !!!
       */
      {
        path: 'crops/v2',
        loadChildren: () => import('./features/v2/crops/crop.module').then((m) => m.CropModule),
        canActivate: [UserGuard],
        data: {
          permissions: [UserPermissions.HARVEST_OFFER],
        },
      },
    ],
  },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
