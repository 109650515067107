import { StatusOffer } from '../enums/status-offer.enum'
import { Crop } from './crop'
import { Location } from './location'
import { Lot } from './lot'
import { Planification } from './planification'
import { Sample } from './sample'

export class Offer {
  public id: number
  public readyToHarvest = false
  public cropName: string
  public fieldName: string
  public fieldTown?: string
  public fieldZipCode?: string
  public cropId: number
  public region
  public regionId?: number
  public growerName: string
  public growerContactPhoneNumber?: string
  public growerId: number
  public cropType
  public varietyId: number
  public varietyName: string
  public typeOfCulture
  public quality
  public otherRisk?: string
  public phyto
  public phytoFilterDate
  public observation
  public contact
  public groupCode
  public plantedDate
  public lastScoutingDate: string
  public floweringDate
  public cityCode
  public location: Location
  public technician
  public forecastHarvestDate
  public priority
  public plantedArea
  public areaToHarvest
  public areaUnit
  public yield
  public yieldUnit
  public quantityToHarvest
  public quantityUnit
  public confirmedHarvestDate?: string
  public bypass
  public status = 1 // init at 1
  public organic = false
  public lastCheckOutSiteDate
  public planification?: Planification
  public lots?: Lot[]
  public durationLots?
  public demandSampleDate?: string[]
  public createdDate
  public updatedDate
  public updatedBy
  public endDate
  public crop: Crop
  public specificationId?: number
  public specificationName?: string
  public samples?: Sample[]
  public risks?: OfferRisk[]
  public varietalType?: string
  public processingType?: string

  constructor(offer?: Offer) {
    this.id = offer?.id ?? null
    this.readyToHarvest = offer?.readyToHarvest ?? null
    this.cropName = offer?.cropName ?? null
    this.fieldName = offer?.fieldName ?? null
    this.cropId = offer?.cropId ?? null
    this.region = offer?.region ?? null
    this.growerName = offer?.growerName ?? null
    this.growerId = offer?.growerId ?? null
    this.growerContactPhoneNumber = offer?.growerContactPhoneNumber ?? null
    this.cropType = offer?.cropType ?? null
    this.varietyId = offer?.varietyId ?? null
    this.varietyName = offer?.varietyName ?? null
    this.typeOfCulture = offer?.typeOfCulture ?? null
    this.quality = offer?.quality ?? null
    this.otherRisk = offer?.otherRisk ?? null
    this.phyto = offer?.phyto ?? null
    this.phytoFilterDate = offer?.phytoFilterDate ?? null
    this.observation = offer?.observation ?? null
    this.contact = offer?.contact ?? null
    this.groupCode = offer?.groupCode ?? null
    this.plantedDate = offer?.plantedDate ?? null
    this.floweringDate = offer?.floweringDate ?? null
    this.lastScoutingDate = offer?.lastScoutingDate ?? null
    this.cityCode = offer?.cityCode ?? null
    this.location = offer?.location
    this.technician = offer?.technician ?? null
    this.forecastHarvestDate = offer?.forecastHarvestDate ?? null
    this.priority = offer?.priority ?? null
    this.plantedArea = offer?.plantedArea ?? null
    this.areaToHarvest = offer?.areaToHarvest ?? null
    this.areaUnit = offer?.areaUnit ?? null
    this.yield = offer?.yield ?? null
    this.yieldUnit = offer?.yieldUnit ?? null
    this.quantityToHarvest = offer?.quantityToHarvest ?? null
    this.quantityUnit = offer?.quantityUnit ?? null
    this.confirmedHarvestDate = offer?.confirmedHarvestDate ?? null
    this.organic = offer?.organic ?? null
    this.bypass = offer?.bypass ?? null
    this.status = offer?.status ?? StatusOffer.offerCreated
    this.lastCheckOutSiteDate = offer?.lastCheckOutSiteDate ?? null
    this.planification = offer?.planification ?? null
    this.lots = offer?.lots ?? null
    this.durationLots = offer?.durationLots ?? null
    this.demandSampleDate = offer?.demandSampleDate ?? null
    this.createdDate = offer?.createdDate ?? null
    this.updatedDate = offer?.updatedDate ?? null
    this.updatedBy = offer?.updatedBy ?? null
    this.endDate = offer?.endDate ?? null
    this.crop = offer?.crop ?? null
    this.samples = offer?.samples ?? null
    this.risks = offer?.risks ?? []
    this.specificationId = offer?.specificationId ?? null
    this.specificationName = offer?.specificationName ?? null
    this.risks = offer?.risks ?? []
    this.varietalType = offer?.varietalType ?? null
    this.processingType = offer?.processingType ?? null
    this.fieldTown = offer?.fieldTown ?? null
    this.fieldZipCode = offer?.fieldZipCode ?? null
  }
}

export class OfferPriorisation extends Offer {
  public declare samples: Sample[]
}

export class OfferDemandSampleDAO {
  id?: number
  date: string
  offerId: number
}

/**
 * @deprecated
 */
export class OfferDAO {
  id: number
  cropId: number
  readyToHarvest: boolean
  priority: number
  typeOfCulture?: string
  quality?: string
  phyto?: string
  phytoFilterDate?: string
  observation?: string
  forecastHarvestDate?: string
  areaToHarvest?: number
  areaUnit?: string
  yield?: number
  quantityToHarvest?: number
  quantityUnit?: string
  // If bypass is set to 1 it means that the 'Adandon sans récolte' toggle is active in the offer edit screen in Agropilot
  bypass: boolean
  updatedDate?: string
  updatedBy?: string
  status?: number
  groupCode?: string
  yieldUnit?: string
  confirmedHarvestDate?: string
  lastCheckOutSiteDate?: string
  createdDate?: string
  endDate?: string
  durationLots?: number
  createdBy?: string

  constructor(offer: OfferDAO) {
    if (offer?.id) {
      this.id = offer?.id
    }
    this.cropId = offer?.cropId
    this.readyToHarvest = offer?.readyToHarvest
    this.priority = offer?.priority
    this.typeOfCulture = offer?.typeOfCulture
    this.quality = offer?.quality
    this.phyto = offer?.phyto
    this.phytoFilterDate = offer?.phytoFilterDate
    this.observation = offer.observation
    this.forecastHarvestDate = offer?.forecastHarvestDate
    this.areaToHarvest = offer?.areaToHarvest
    this.areaUnit = offer?.areaUnit
    this.yield = offer?.yield
    this.quantityToHarvest = offer?.quantityToHarvest
    this.quantityUnit = offer?.quantityUnit
    this.bypass = offer?.bypass
    this.updatedDate = offer?.updatedDate
    this.updatedBy = offer?.updatedBy
    this.status = offer?.status ?? 1
    this.groupCode = offer?.groupCode
    this.yieldUnit = offer?.yieldUnit
    this.confirmedHarvestDate = offer?.confirmedHarvestDate
    this.lastCheckOutSiteDate = offer?.lastCheckOutSiteDate
    this.createdDate = offer?.createdDate
    this.createdBy = offer?.createdBy
    this.endDate = offer?.endDate
    this.durationLots = offer?.durationLots
  }
}

/**
 * @deprecated
 */
export class OfferRequest {
  id: number
  readyToHarvest: number
  priority: string
  typeOfCulture: string
  quality: string
  risk: string
  phyto: string
  phytoFilterDate: string
  observation: string
  forecastHarvestDate: string
  areaToHarvest: string
  areaUnit: string
  yield: string
  quantityToHarvest: string
  quantityUnit: string
  bypass: string
  demandSampleDate: string
  updatedDate: string
  updatedBy: string
  status: number
  groupCode: string
  yieldUnit: string
  confirmedHarvestDate: string
  lastCheckOutSiteDate: string
  createdDate: string
  endDate: string
  durationLots: string
  cropId: number
  cropName: string
  shortName: string
  cropPlantedDate: string
  cropPlannedHarvestDate: string
  cropPlantedArea: string
  cropIntendedArea: string
  cropAreaUnit: string
  cropVarietyId: number
  cropFieldId: string
  cropStartDate: string
  cropEndDate: string
  cropNotes: string
  cropTechnician: string
  cropForecastHarvestDate: string
  cropGrowingSystem: string
  cropAssociatedCrop: string
  cropLastScoutingDate: string
  cropLastFloweringDate: string
  cropVarietyName: string
  cropCropType: string
  cropFarmName: string
  cropGrowerId: number
  cropGrowerName: string
  cropFieldName: string
  cropFieldArea: string
  cropFieldAreaUnit: string
  cropRegionId: number
  region: string
  cropCountry: string
  cropLatitude: string
  cropLongitude: string
  cropPrecision: string
  cropBoundary: string
  demandSampleDates: string
  contact: string
  riskIds: string
  riskScoutingIds: string
  riskTypes: string
  riskNames: string
}

export class OfferRisk {
  id?: number
  scoutingId?: number
  scoutingSubType: string
  name: string
}
