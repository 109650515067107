import { NetworkService } from '@agroone-front/shared'
import { HarvestOfferPriorisation, IFilters, Offer, PatchOfferDto, RangeFilter, SaveOffer } from '@agroone/entities'
import { addDays, isWithinInterval } from '@agropilot/app/core/library/date'
import { parseDateFilter } from '@agropilot/app/core/library/date-utility'
import { OfferFormDto } from '@agropilot/app/features/v2/crops/dtos/offer-form.dto'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

export class inMemoryFilters {
  forecastHarvestDate?: RangeFilter

  constructor(filter: IFilters) {
    this.forecastHarvestDate =
      filter?.forecastHarvestDate?.start && filter?.forecastHarvestDate?.end ? filter.forecastHarvestDate : null
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  public offers: Offer[]

  public offersWithoutMaturitySubject: BehaviorSubject<Offer[]> = new BehaviorSubject([])
  public offersWithoutMaturity$: Observable<Offer[]> = this.offersWithoutMaturitySubject.asObservable()

  private inMemoryFilter: inMemoryFilters

  constructor(private http: HttpClient, private networkService: NetworkService) {}

  public get(id: number): Observable<Offer> {
    return this.http.get<Offer>(`${environment.apiUrl}/offers-v2/${id}`).pipe(
      // Map the received offer to a new Offer instance
      map((offer) => new Offer(offer)),

      catchError((error) => {
        // Try to retrieve the offer from the local cache if available
        const cachedOffer = this.offers?.find((offerItem) => offerItem.id === id)

        return cachedOffer ? of(cachedOffer) : throwError(() => error)
      })
    )
  }

  public getAll(filters?: IFilters): Observable<Offer[]> {
    return from(
      this.networkService.getAllFromPaginated<Offer>(
        `${environment.apiUrl}/offers-v2?query=${JSON.stringify(filters)}`,
        {
          params: { pageLength: 1000 },
        }
      )
    ).pipe(
      map((offers) => {
        this.offersWithoutMaturitySubject.next(offers)
        if (offers) {
          if (this.inMemoryFilter?.forecastHarvestDate) {
            return offers.filter((o: Offer) =>
              isWithinInterval(
                o.forecastHarvestDate,
                parseDateFilter(this.inMemoryFilter.forecastHarvestDate.start),
                parseDateFilter(addDays(this.inMemoryFilter.forecastHarvestDate.end, 1))
              )
            )
          }
          return offers
        }
      }),
      tap((offers: Offer[]) => {
        this.offers = offers
      })
    )
  }

  public getPriorisation(filter?: string): Observable<HarvestOfferPriorisation[]> {
    return this.http.get<HarvestOfferPriorisation[]>(
      `${environment.apiUrl}/offers-v2/priorisation${filter ? filter : ''}`
    )
  }

  public post(offer: SaveOffer): Observable<Offer> {
    return this.http.post<Offer>(`${environment.apiUrl}/offers-v2`, offer)
  }

  public update(offer: SaveOffer): Observable<Offer> {
    return this.http.put<Offer>(`${environment.apiUrl}/offers-v2`, offer)
  }

  public patch(offerId: number, offer: PatchOfferDto): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.apiUrl}/offers-v2/${offerId}`, offer)
  }

  public updateDemandSampleDates(dates: string[], offerId: number): Observable<string[]> {
    return this.http.put<string[]>(`${environment.apiUrl}/offers-v2/demand-samples/${offerId}`, { dates })
  }

  public delete(id: number): Observable<Offer> {
    return this.http.delete<Offer>(`${environment.apiUrl}/offers-v2/${id}`)
  }

  public resetPriorisation(body: { date?: string; id?: string }) {
    return this.http.patch(`${environment.apiUrl}/offers-v2/reset`, body)
  }
}
